.body {
	position: relative;
	// padding-top: $header-height-small;
	// @include breakpoint(smallish) {
	// 	padding-top: $header-height-smallish;
	// }
	&--home {
		@include vh100-ios("padding-top");
	}
}
.section__header-bottom {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.site-header {
	// padding-top: 1rem;
	// padding-bottom: 1rem;
	background: #fff;	
	a {
		text-decoration: none;
	}
	// sticky header
	&.header__js {
		position: absolute;
		width: 100%;
		z-index: 10;
		&--hidden {
			 position: fixed;
			 //top: calc(#{$headerHeight} * -1);
		}
		&--show, &--fixed {
			 position: fixed;
			 top: 0;
		}
		transition: top .4s ease-in-out, box-shadow .3s ease-in-out;
	}
	&__logo {
		@extend %gutters;
		line-height: 2rem;
		margin-bottom: 1rem;
		&__text {
			display: none;
		}
		&__svg {
			width: 8.75rem;
			height: 2rem;
			display: block;
		}
	}
	&__nav {
		@extend %gutters;
		margin-bottom: 0;
		&__item {
			@include sans-serif-bold;
			float: left;
			padding-right: 1rem;
		}
		&__link {
			color: $gray-5;
			@include no-link;

			&--selected, &:hover {
				color: $gray-6;
			}
		}
		&__new-tab {
			display: inline-block;
			width: .96rem;
			height: .96rem;
			margin: .5rem 0 0 .2rem;
			.site-header__nav__link:hover & path {
				fill: $gray-6;
			}
		}
	}
	@include breakpoint(medium) {
		&__logo {
			float: left;
			margin-bottom: 0;
		}
		&__nav {
			float: right;
			&__item--last {
				padding-right: 0;
			}
		}
	}
	/******************************************************************************************
	HOME NAV
	******************************************************************************************/
	&--home {
		.JS & {
			@include vh100-ios("top");
		}
	}
}
